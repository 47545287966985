import { useTranslation } from '@pancakeswap/localization'
import { Button, Flex, Text, useMatchBreakpoints } from '@pancakeswap/uikit'

import { NextLinkFromReactRouter } from '@pancakeswap/widgets-internal'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { ASSET_CDN } from 'config/constants/endpoints'
import useTheme from 'hooks/useTheme'
import { useLayoutEffect, useRef } from 'react'
import { styled } from 'styled-components'
import { useAccount } from 'wagmi'
import { useDrawCanvas } from '../hooks/useDrawCanvas'
import { useDrawSequenceImages } from '../hooks/useDrawSequence'
import { checkIsIOS, useIsIOS } from '../hooks/useIsIOS'

const Container = styled.div``

const StyledText = styled(Text)`
  color: #e81899;
  font-weight: 600;
  line-height: 1.25;
  font-size: 24px;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 48px;
  }
`
const Logo = styled.div`
  display: flex;
  display: absolute;

  width: 755px;
  height: 755px;
  z-index: 99;
`
const StyledYellowText = styled(Text)`
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  line-height: ${({ theme }) => (theme.isMobile ? 0 : 100)} px;
  margin: 8px 0;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 0;
    font-size: 64px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: 86px;
  }
  ${({ theme }) => theme.mediaQueries.xxl} {
    font-size: 100px;
  }
`

const CommunityTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  transition: background-color 0.25s ease-in-out;
  svg path {
    fill: ${({ theme }) => theme.colors.primary};
    transition: fill 0.25s ease-in-out;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    svg path {
      fill: ${({ theme }) => (theme.isDark ? '#000000' : '#ffffff')};
    }
  }

  width: 48px;
  height: 48px;

  > svg {
    width: 24px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 64px;
    height: 64px;

    > svg {
      width: 32px;
    }
  }
`

const ChainContainer = styled(Flex)`
  justify-content: center;
  font-size: 32px;
  font-weight: 600;

  ${({ theme }) => theme.mediaQueries.md} {
    justify-content: flex-start;
    font-size: 80px;
  }

  > img {
    width: 150px;

    ${({ theme }) => theme.mediaQueries.md} {
      width: 319px;
    }
  }
`

const ComingSoonText = styled(Text)`
  justify-content: center;
  font-size: 20px;
  flex-direction: column;
  gap: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    font-size: 24px;
    gap: 24px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    justify-content: flex-start;
  }
`

const width = 1080
const height = 1080

const Hero = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { theme } = useTheme()
  const { isDesktop, isMobile, isXs, isMd } = useMatchBreakpoints()
  const videoRef = useRef<HTMLVideoElement>(null)
  const starVideoRef = useRef<HTMLVideoElement>(null)
  const cakeVideoRef = useRef<HTMLVideoElement>(null)
  const rock01VideoRef = useRef<HTMLVideoElement>(null)
  const rock02VideoRef = useRef<HTMLVideoElement>(null)
  const rock03VideoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const internalRef = useRef(0)
  const seqInternalRef = useRef(0)
  const { isIOS } = useIsIOS()
  const { drawImage, isVideoPlaying } = useDrawCanvas(
    videoRef,
    canvasRef,
    internalRef,
    width,
    height,
    () => {
      if (isVideoPlaying.current === false) {
        isVideoPlaying.current = true
        internalRef.current = window.requestAnimationFrame(() => {
          drawImage?.()
        })
      }
    },
    () => {
      if (videoRef.current) {
        videoRef.current.currentTime = 3
        videoRef.current.play()
      }
    },
    [starVideoRef, cakeVideoRef, rock01VideoRef, rock02VideoRef, rock03VideoRef],
  )

  useLayoutEffect(() => {
    starVideoRef.current?.play()
    cakeVideoRef.current?.play()
    rock01VideoRef.current?.play()
    rock02VideoRef.current?.play()
    setTimeout(() => {
      rock03VideoRef.current?.play()
    }, 3000)
    return () => {
      clearInterval(seqInternalRef.current)
      cancelAnimationFrame(internalRef.current)
    }
  }, [])

  const { drawSequenceImage, playing } = useDrawSequenceImages(
    `${ASSET_CDN}/web/landing/hero-sequence`,
    checkIsIOS() || isMobile ? 70 : 0,
    canvasRef,
    seqInternalRef,
    () => clearInterval(seqInternalRef.current),
    () => {
      if (playing.current === false) {
        playing.current = true
        seqInternalRef.current = window.setInterval(() => {
          drawSequenceImage(500, 500)
        }, 1000 / 15)
      }
    },
    true,
  )

  return (
    <Container>
      <Flex
        position="relative"
        flexDirection={['column-reverse', null, null, 'row']}
        alignItems={['center', null, null, 'center']}
        justifyContent="center"
        mt={[0, null, 0]}
        pl={['0px', '0px', '0px', '30px']}
        id="homepage-hero"
      >
        <Flex flex="1" flexDirection="column">
          <Text textAlign={isMobile || isMd ? 'center' : 'left'} pr={isMobile ? 0 : '10px'} mb="16px">
            <StyledText>Your familiar trading experience</StyledText>
            <StyledYellowText style={{ lineHeight: isMobile ? '100%' : '100px' }}>
              A Based DEX {!isMobile && <br />}
              on the based {!isMobile && <br />}Rollup
            </StyledYellowText>
            {/* <ChainContainer alignItems="center" style={{ gap: '24px' }}>
              on Panko
               <img src="/images/home/mantle-logo.svg" alt="mantle-logo" /> 
            </ChainContainer> */}
          </Text>
          <Text
            mb="24px"
            color={theme.isDark ? '#ECBCE5' : '#7A6EAA'}
            maxWidth={600}
            fontSize={isMobile ? '16px' : '20px'}
            textAlign={isMobile || isMd ? 'center' : 'left'}
            lineHeight="30px"
            fontWeight={500}
          >
            {/* {t('Trade, earn, and own crypto on the all-in-one multichain DEX')} */}
            Trade with all-in-one DEX Panko and earn rewards now!
          </Text>

          {/* <ComingSoonText
            display="flex"
            mb="24px"
            color={theme.colors.primary}
            maxWidth={600}
            textAlign={isMobile || isMd ? 'center' : 'left'}
            lineHeight="110%"
            fontWeight={500}
            style={{ alignItems: 'center' }}
          >
            <span>Coming soon, follow us on</span>
            <Flex style={{ gap: 16 }}>
              <Link href="https://twitter.com/Panko" external>
                <CommunityTag>{cloneElement(<TwitterIcon />)}</CommunityTag>
              </Link>
              <Link href="https://t.me/OfficialMancake" external>
                <CommunityTag>{cloneElement(<TelegramIcon />)}</CommunityTag>
              </Link>
            </Flex>
          </ComingSoonText> */}

          <Flex justifyContent={isMobile || isMd ? 'center' : 'start'}>
            {!account && (
              <ConnectWalletButton
                style={{
                  borderRadius: isXs ? 16 : undefined,
                  fontSize: '20px',
                  color: '#0B080D',
                  fontWeight: 500,
                  background: '#E81899',
                }}
                scale="md"
                mr="8px"
              />
            )}
            <NextLinkFromReactRouter to="/swap">
              <Button
                scale="md"
                style={{
                  borderRadius: isXs ? 16 : undefined,
                  fontSize: '20px',
                  fontWeight: 500,
                  color: '#E81899',
                  border: '2px solid #E81899',
                  background: 'none',
                }}
                variant={!account ? 'secondary' : 'primary'}
              >
                {t('Trade Now')}
              </Button>
            </NextLinkFromReactRouter>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  )
}

export default Hero
