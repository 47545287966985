import { Button, Flex, Link } from '@pancakeswap/uikit'
import { styled } from 'styled-components'

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  background: black;
  padding-bottom: 110px;
  min-height: 500px;
  position: relative;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-bottom: 40px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    padding-bottom: 70px;
  }
`

const StyledText = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 40px;
    line-height: 52px;
  }
`

const StyledTextContent = styled.div`
  font-size: 14px;
  line-height: 22px;
  padding-right: 10px;

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 16px;
    line-height: 24px;
  }
`

const AffiliateTextContainer = styled(Flex)`
  flex-direction: column;
  gap: 16px;

  background: linear-gradient(90deg, #571b43 0%, #401138 100%);
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px);

  width: auto;
  box-sizing: border-box;
  max-width: 1040px;
  padding: 32px 48px 181px;
  margin: 112px 16px;
  border-radius: 20px;

  position: relative;
  z-index: 2;

  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 56px 315px 56px 87px;
    gap: 13px;
  }
`

const PancakeTextColor = styled.span`
  font-weight: 600;
  color: #e81899;
`

const MancakeTextColor = styled.span`
  font-weight: 600;
  color: #ec5d91;
`

const PancakeBunImage = styled.img`
  position: absolute;
  height: 200px;
  left: 18%;
  bottom: -28px;

  ${({ theme }) => theme.mediaQueries.sm} {
    left: 37%;
    bottom: -48px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    top: auto;
    left: auto;
    right: -18px;
    bottom: -10px;
    height: auto;
    width: 351px;
  }
`

const PancakeBunAssetsRightImage = styled.img`
  position: absolute;
  top: 60px;
  right: -70px;

  ${({ theme }) => theme.mediaQueries.sm} {
    top: 120px;
    right: -70px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    top: 220px;
    right: -58px;
  }
`

const PancakeBunAssetsLeftImage = styled.img`
  position: absolute;
  top: 60px;
  left: -14px;

  ${({ theme }) => theme.mediaQueries.sm} {
    top: 100px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    top: 170px;
  }
`

const CenterCakeImage = styled.img`
  position: absolute;
  left: 10px;
  bottom: 120px;
  width: 59px;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 85px;
    right: auto;
    top: auto;
    left: 35%;
    bottom: -30px;
  }
`
const RightTopCakeImage = styled.img`
  position: absolute;
  right: 10px;
  bottom: 200px;
  width: 59px;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 83px;
    right: 350px;
    top: 70px;
  }
`

const ReadMoreButton = styled(Button)`
  font-size: 16px;
  font-weight: 500;
  margin-top: 4px;
  background: #e81899;
  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: 18px;
    font-size: 20px;
  }
`

const WaveContainer = styled.div`
  position: relative;
  top: -2px;
  width: 100%;

  > img {
    width: 100%;
    transform: scale(1.15);
  }
`
const BottomBg = styled.img`
  position: abosolute;
  bottom: -60px;
  right: 20px;
  position: absolute;
  width: 100dvw; 
  height: auto;
  bottom: 0;
  left: 0;
  right: 0; 

  ${({ theme }) => theme.mediaQueries.xl} {
    bottom: -60px; 
`

const TopBg = styled.img`
  position: absolute;
  top: -20px;
  left: 0;
  max-width: 100dvw;
  min-width: fit-content;
  transform: scale(3);
`

export const AffiliateSection = () => {
  return (
    <Container alignItems="center" flexDirection="column">
      <WaveContainer>
        <img src="/images/home/bottom-wave.svg" alt="wave" />
      </WaveContainer>
      <AffiliateTextContainer>
        <StyledText>
          Panko as an affiliate <br />
          fork of <PancakeTextColor>PancakeSwap</PancakeTextColor>
        </StyledText>
        <StyledTextContent>
          <MancakeTextColor>Panko</MancakeTextColor> is an affiliate fork of{' '}
          <PancakeTextColor style={{ color: '#EDF06B' }}>PancakeSwap</PancakeTextColor>, with the mission to become the
          best go-to platform that provides deep liquidity and top-class execution for native tokens on Mantle Network.
          We are dedicated to follow the core operating structure of PCS and contribute values accrual to its ecosystem
          and token holders
        </StyledTextContent>
        <Link
          target="_blank"
          href="https://forum.pancakeswap.finance/t/discussion-for-proposal-to-official-launch-mancake-as-a-pcs-affiliate-on-mantle-network/511"
        >
          <ReadMoreButton>Read more</ReadMoreButton>
        </Link>
        <PancakeBunImage src="/images/home/panko/pankoBigAffiliate.png" alt="pancake-bun" />
        <CenterCakeImage src="/images/home/panko/assetOneAffiliate.png" alt="center-cake" />
        <RightTopCakeImage src="/images/home/panko/assetTwoAffiliate.png" alt="center-cake" />
      </AffiliateTextContainer>
      {/* <PancakeBunAssetsRightImage src="/images/home/pc-decoration-right.svg" alt="pancake-assets-right" />
      <PancakeBunAssetsLeftImage src="/images/home/pc-decoration-left.svg" alt="pancake-assets-left" /> */}
      <BottomBg src="/images/home/panko/hero_bottom.png" alt="hero-bottom" />
      <TopBg src="/images/home/panko/hero_top.svg" alt="hero-top" />
    </Container>
  )
}
