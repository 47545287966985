import { Box, PageSection, useMatchBreakpoints } from '@pancakeswap/uikit'
import Image from 'next/image'
import { styled } from 'styled-components'
import { AffiliateSection } from './components/AffiliateSection'
import { DiscoverEcosystem } from './components/DiscoverEcosystem'
import Hero from './components/Hero'
import Tokenomics from './components/Tokenomics'

const StyledHeroSection = styled(PageSection)`
  overflow: hidden;
  padding-top: 24px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 80px;
  }
`

const HeroBunnyImage = styled(Image)<{ right?: number; bottom?: number }>`
  position: absolute;
  right: ${({ right }) => right || -10}px;
  height: auto;
  bottom: ${({ bottom }) => bottom || 35}px;
  z-index: 98;
  width: 500px;

  ${({ theme }) => theme.mediaQueries.sm} {
    bottom: 0px;
    @media (min-height: 832px) {
      bottom: ${({ bottom }) => bottom || 32}px;
    }
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    width: ${({ width }) => width || 644}px;
  }
`

const HeroWaveImage = styled.img`
  position: absolute;
  width: 100%;
  height: 150px;
  bottom: -40px;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;

  ${({ theme }) => theme.mediaQueries.sm} {
    bottom: -60px;
    height: 200px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    bottom: -100px;
    height: 274px;

    @media (min-height: 832px) {
      height: 274px;
    }
  }
`

const HeroCakeImage = styled.img`
  position: absolute;
  height: 300px;
  top: 280px;
  left: 0;
  display: none;

  ${({ theme }) => theme.mediaQueries.xl} {
    display: block;
  }
`
const HeroBottom = styled.img`
  position: absolute;
  width: 100dvw; 
  height: auto;
  bottom: 45px;
  left: 0;
  right: 0; 
  z-index:-33;

  ${({ theme }) => theme.mediaQueries.xl} {
    bottom: 0; 
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const { isDesktop, isMobile } = useMatchBreakpoints()
  return (
    <Box style={{ overflow: 'hidden', boxSizing: 'border-box' }}>
      <style jsx global>
        {`
          #home-1 .page-bg {
            background: linear-gradient(180deg, #14080d -25.34%, #12070b 41.54%, #4a0821 105.07%);
          }
        `}
      </style>
      <StyledHeroSection
        style={{
          position: 'relative',
          height: 'calc(100dvh - 56px)',
          paddingBottom: 0,
        }}
        innerProps={{ style: { margin: '0', width: '100%', overflow: 'visible', padding: '0' } }}
        containerProps={{
          id: 'home-1',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <Hero />
        {/*  <HeroCakeImage src="/images/home/hero-cake.svg" alt="hero-cake" /> */}

        {/*     <HeroBunnyImage width={755} height={756} src="/images/home/pankoDesktopLogo.svg" alt="hero-bunny" /> */}
        <HeroBunnyImage
          width={isMobile ? 275 : 790}
          height={isMobile ? 289 : 866}
          right={isMobile ? -40 : -100}
          bottom={isMobile ? 50 : 120}
          src="/images/home/pankoLogo.png"
          alt="panko"
        />

        <HeroBottom src="/images/home/panko/hero_bottom.png" alt="hero-bottom" />
        <HeroWaveImage src="/images/home/panko/wave.svg" alt="hero-wave" />
      </StyledHeroSection>
      <DiscoverEcosystem />
      <Tokenomics />
      <AffiliateSection />
    </Box>
  )
}

export default Home
